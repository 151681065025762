.react-select__menu{
    z-index: 110 !important;
}

#branding{
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -1px;
    font-family: "Avenir Next", "Avenir", Helvetica, sans-serif;
    font-size: 22px;
    margin-bottom: -6px;
}

#branding span{
    color: #0d9040;
}

#headerContainer{
    border-bottom: rgb(13, 81, 22) 2px solid;
    padding-bottom: 8px;;
}

header .responsevault_link a{
    color: #222;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
}

#hero{
    background:  rgb(5 75 8) 0%;
    background: linear-gradient(115deg, rgb(5 75 8) 0%, rgb(9 110 19) 47%, rgb(9 154 75) 100%);
    border-bottom: rgb(5 75 8) 0% 1px solid;
}

#hero h2{
    font-size: 50px;
    line-height: 1.1;
    font-weight: 700;
    padding: 60px 5px 40px 5px;
}

#hero .btn{
    width: 50%;
    max-width: 400px;
}


.input_table_cell_0-0,
.input_table_cell_1-1,
.input_table_cell_2-2,
.input_table_cell_3-3,
.input_table_cell_0-1,
.input_table_cell_1-2,
.input_table_cell_2-3,
.input_table_cell_3-4,
.input_table_cell_4-4,
.input_table_cell_2-1,
.input_table_cell_3-2 {
  background-color: #fffa5b;
}
.input_table_cell_1-0,
.input_table_cell_2-0,
.input_table_cell_3-0,
.input_table_cell_3-1,
.input_table_cell_4-0,
.input_table_cell_4-1,
.input_table_cell_4-2,
.input_table_cell_4-3 {
  background-color: #a0de91;
}

.input_table_cell_0-2,
.input_table_cell_0-3,
.input_table_cell_0-4,
.input_table_cell_1-3,
.input_table_cell_1-4,
.input_table_cell_2-4 {
  background-color: #ffa293;
}

.safety-report-container{
    padding: 10px;
    max-width: 1164px;
    margin: auto;
}

#root_steps .form-label{
    display: none;
}

.field-array .array-item-list .array-item{
    background: none;
    border: none;
    padding: 0;
    border-bottom: #cacaca 1px solid;
}

.field-array .array-item-list .array-item .row{
    margin-left: -5px;
    margin-right: -5px;
}

.array-item [class^="col"]{
  padding: 0 5px;  
}


.array-item .field-boolean .field-radio-group .radio input + span{
    display: inline-block;
    padding: 0.85em 1.4em;
    margin: 0 0.5em 0 0;
    cursor: pointer;
    border-radius: 0.25em;
    border: #bbb 1px solid;
    box-shadow: 0 0, inset 0 -3px 0 rgb(0 0 0 / 22%);
    transition: 0.3s;
    user-select: none;
}

.array-item .field-boolean .field-radio-group .radio{
    display: inline-block;
}

.array-item .field-boolean .field-radio-group .radio:first-child input:checked + span{
    background: green;
    color: white;
    font-weight: bold;
    
}

.array-item .field-boolean .field-radio-group .radio:last-child input:checked + span{
    background: red;
    color: white;
    font-weight: bold;
}

.array-item .field-boolean .field-radio-group input{
    position: absolute;
    clip: rect(0,0,0,0);
}

.cover:after{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    content: ' ';
}

@media screen and (max-width: 750px){

    #hero{
        padding: 10px 15px;
    }

    #hero .btn{
        display: block;
        width: 100%;
        max-width: 100%;
    }

    .safety-report-container{
        padding: 10px;
    }

   

    legend, .array-item *, .input_table_row, .table-responsive th,
    .safety-report-container *, 
    .safety-report-container input, 
    .safety-report-container select, 
    .safety-report-container textarea, 
    .safety-report-container button, 
    {
        font-size: 1rem !important;
    }

    .input_table_row .headerCell, .input_table_row .buttonCell, .table-responsive th{
        padding: 2px 1px;
        font-size: .75rem !important;
    }
    
    .input_table_cell{
        padding: 2px !important;
        vertical-align: middle;
    }
}